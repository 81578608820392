export const scrollToTop = (options: ScrollToOptions = {}): void => {
  if (typeof window === `undefined`) return

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
    ...options,
  })
}
