import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import queryString, { ParsedQuery } from 'query-string'
import { useCallback, useMemo } from 'react'

import { queryObjectIsEqual } from 'utils/queryObjectIsEqual'
import { scrollToTop } from 'utils/scrollToTop'

export const QUERY_STRING_OPTIONS = {
  arrayFormat: 'comma' as const,
  skipNull: true,
}

export type QueryParams = { [key: string]: (string | null)[] | string | null }

type SetQueryParams = (
  params: QueryParams,
  options: {
    replaceHistory: boolean
  }
) => void

type UseQueryParams = () => {
  setQueryParams: SetQueryParams
  queryParams: QueryParams
}

export const useQueryParams: UseQueryParams = () => {
  const location = useLocation()
  const queryParams = useQueryParamsObject()

  const setQueryParams = useCallback<SetQueryParams>(
    (newParamsObject, { replaceHistory }) => {
      const paramsObject = getQueryParamsObjectFromLocation(location)
      const mergedParamsObject = {
        ...paramsObject,
        ...newParamsObject,
      }

      const newQueryParams = queryString.stringify(
        mergedParamsObject,
        QUERY_STRING_OPTIONS
      )

      if (!queryObjectIsEqual(mergedParamsObject, paramsObject)) {
        // TODO: get onQueryParamsChanged callback, move the code below to where sorting/filtering happens
        setTimeout(() => {
          navigate(`${location.pathname}?${newQueryParams}`, {
            replace: replaceHistory,
          })
        }, 300)
        // TODO: small hack: gatsby scrolls to top automatically, unfortunately - not smoothly,
        // so setTimeout gives some time for scroll to initiate.
        // Maybe 'useScrollRestoration' could help?
        scrollToTop()
      }
    },
    [location]
  )

  return { queryParams, setQueryParams }
}

export const useQueryParamsObject = (): QueryParams => {
  const location = useLocation()
  return useMemo(() => getQueryParamsObjectFromLocation(location), [location])
}

type GetQueryParamsObjectFromLocation = (
  location: ReturnType<typeof useLocation>
) => ParsedQuery

const getQueryParamsObjectFromLocation: GetQueryParamsObjectFromLocation =
  location => queryString.parse(location.search, QUERY_STRING_OPTIONS)
