import styled from 'styled-components'

import {
  sortTypes,
  useSortContext,
  SortType,
} from 'components/Context/SortContext'
import { PRODUCTS_LIST_SORTING } from 'constants/zIndexes'
import { Flex, Text } from 'ui/common'

const sortsButtonsTexts: Record<SortType, string> = {
  default: 'Domyślnie',
  nameAsc: 'Nazwa - A do Z',
  nameDesc: 'Nazwa - Z do A',
  priceAsc: 'Cena - rosnąco',
  priceDesc: 'Cena - malejąco',
}

interface ProductsListSortingProps {
  categoryDisplayName?: string
  visibleProductsQuantity?: number
}

const ProductsListSorting: React.FC<ProductsListSortingProps> = ({
  categoryDisplayName,
  visibleProductsQuantity,
}) => {
  const { sortType, setSortType } = useSortContext()

  return (
    <Flex
      as="menu"
      position="sticky"
      top="0px"
      minHeight="48px"
      flexDirection={{ _: 'column', mobileL: 'row' }}
      justifyContent="space-between"
      alignItems="center"
      backgroundColor="white"
      borderBottom="#cccccc88 1px solid"
      mb="m"
      mx="-16px"
      px="s"
      zIndex={PRODUCTS_LIST_SORTING}
    >
      <Text textAlign="center" fontWeight="medium" fontSize="m" marginRight="m">
        <Text as="span" fontSize="m" fontWeight="bold">
          {categoryDisplayName}
        </Text>
        {` `}
        {getProductsQuantity(visibleProductsQuantity)}
      </Text>
      <Flex alignItems="center">
        <Text fontSize="xs" textAlign="center" mr="m">
          Sortuj
        </Text>
        <SortTypeSelect
          name="sortType"
          id="sortType"
          value={sortType}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setSortType(e.target.value as SortType)
          }}
        >
          {sortTypes.map(sort => (
            <option key={sort} value={sort}>
              {sortsButtonsTexts[sort]}
            </option>
          ))}
        </SortTypeSelect>
      </Flex>
    </Flex>
  )
}

export default ProductsListSorting

const SortTypeSelect = styled.select`
  font-size: ${p => p.theme.fontSizes.xs}px;
  padding: ${p => p.theme.space.xxs}px;
  cursor: pointer;
`

// TODO: i18n
const getProductsQuantity = (quantity?: number): string => {
  if (!quantity) return '(brak produktów)'
  if (quantity === 1) return '(1 produkt)'
  if (
    (quantity % 100 < 12 || quantity % 100 > 14) &&
    quantity % 10 >= 2 &&
    quantity % 10 <= 4
  )
    return `(${quantity} produkty)`
  else return `(${quantity} produktów)`
}
