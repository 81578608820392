import { useCallback, useEffect, useState } from 'react'

import { useQueryParams, QueryParams } from 'hooks/useQueryParams'
import { isSortType, SortContext, SortType } from './SortContext'

const getSortTypeFromQueryParams = (queryParams: QueryParams) => {
  const { sortType } = queryParams
  let sort: SortType = 'default'

  if (Array.isArray(sortType)) {
    if (isSortType(sortType[0])) sort = sortType[0]
  } else if (sortType && isSortType(sortType)) {
    sort = sortType
  }

  return sort
}

export const SortProvider: React.FC = ({ children }) => {
  const { queryParams, setQueryParams } = useQueryParams()

  const [sortType, setSortType] = useState<SortType>(
    getSortTypeFromQueryParams(queryParams)
  )

  // Read sort type from query params
  useEffect(() => {
    const sort = getSortTypeFromQueryParams(queryParams)
    setSortType(sort)
  }, [queryParams])

  // Set sort type on query params first
  const setSortTypeParam = useCallback(
    (sort: SortType) => {
      setQueryParams({ sortType: sort }, { replaceHistory: true })
    },
    [setQueryParams]
  )

  return (
    <SortContext.Provider
      value={{
        sortType,
        setSortType: setSortTypeParam,
      }}
    >
      {children}
    </SortContext.Provider>
  )
}
