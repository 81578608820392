import styled from 'styled-components'

import Link from 'components/Link/Link'
import { categories } from 'data/categories'
import { Box, Flex, Grid, Text } from 'ui/common'

interface ProductsListFilteringProps {
  query?: string
}

const ProductsListFiltering: React.FC<ProductsListFilteringProps> = () => {
  return (
    <Flex
      as="aside"
      flexDirection="column"
      flexShrink={0}
      width={{ _: '100%', monitorS: '220px' }}
      position="relative"
    >
      <Box as="menu" position="sticky" top="100px" paddingLeft="none">
        <Text as="h2" fontSize="m" paddingRight="m" marginBottom="m">
          Kategorie
        </Text>
        <Grid
          as="ul"
          gridTemplateColumns={{
            _: '1fr 1fr',
            monitorS: '1fr',
          }}
          justifyItems={{
            _: 'center',
            monitorS: 'unset',
          }}
          alignItems="center"
          paddingLeft="none"
          mb="s"
        >
          {categories.map(({ categoryDisplayName, slug }) => (
            <Box key={categoryDisplayName} position="relative">
              <StyledLi>
                <StyledCategoryLink
                  to={`/dekoracje/${slug}`}
                  partiallyActive
                  activeClassName="active-filter"
                >
                  {categoryDisplayName}
                </StyledCategoryLink>
                <StyledCloseButtonLink
                  tabIndex={-1}
                  to="/dekoracje/dekoracje-slubne-i-eventowe"
                >
                  <CloseButton>X</CloseButton>
                </StyledCloseButtonLink>
              </StyledLi>
            </Box>
          ))}
        </Grid>
      </Box>
    </Flex>
  )
}

export default ProductsListFiltering

const CloseButton = styled(Box).attrs(() => ({ as: 'button' }))`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  padding: 0.5rem;
  line-height: 1rem;
  font-size: 1rem;
  min-width: 2rem;
  height: 100%;
  text-align: center;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background: ${p => p.theme.colors.gray300};
    font-weight: bold;
  }
`

const StyledCategoryLink = styled(Link)`
  display: flex;
  padding: 0.5rem;

  &.active-filter {
    font-weight: bold;
    letter-spacing: 0.4px;
  }

  &:hover,
  &:active,
  &:focus {
    background: ${p => p.theme.colors.gray300};
  }
`

const StyledCloseButtonLink = styled(Link)`
  display: none;

  ${StyledCategoryLink}.active-filter + & {
    display: block;
  }
`

const StyledLi = styled.li`
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -0.5rem;
  margin-right: 2rem;

  &:hover,
  &:active,
  &:focus {
    background: ${p => p.theme.colors.gray300};
  }

  @media ${({ theme }) => theme.devices.monitorS.max} {
    text-align: center;
  }
`
