import { createContext, useContext } from 'react'

export const sortTypes = [
  'default', // sort by product Id
  'nameAsc',
  'nameDesc',
  'priceAsc',
  'priceDesc',
] as const

export type SortType = typeof sortTypes[number]

export const isSortType = (sort: string | null): sort is SortType => {
  return !!sort && (sortTypes as readonly string[]).includes(sort)
}

type SortContextType = {
  sortType: SortType
  setSortType: (sort: SortType) => void
}

export const SortContext = createContext<SortContextType>({
  sortType: 'default',
  setSortType: () => {},
})

export const useSortContext = (): SortContextType => useContext(SortContext)
