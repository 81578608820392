import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import Link from 'components/Link/Link'
import { FileConnection, GlamoProduct } from 'types/gatsby'
import { Box, Flex, FlexProps, Text } from 'ui/common'
import { formatPrice } from 'utils/formatPrice'

interface ProductListCardProps {
  product: GlamoProduct
}

const ProductListCard: React.FC<ProductListCardProps> = ({ product }) => {
  const productsImages =
    useStaticQuery<Record<string, Pick<FileConnection, 'edges'>>>(imagesQuery)

  const { name, glamoId, slug, price, mainImageFile, mainImageAlt } = product
  const imageEdge =
    mainImageFile &&
    productsImages.allProductsImages.edges.find(fileEdge => {
      return fileEdge.node.name === mainImageFile
    })
  // check if all products have corresponding image file available
  if (process.env.NODE_ENV === 'development' && mainImageFile && !imageEdge) {
    // eslint-disable-next-line no-console
    console.log(
      `[INFO] Oops: Missing '${mainImageFile}.jpg', product: '${name}' (id: ${glamoId})`
    )
    throw new Error(
      `Please add a correct query for a file: '${mainImageFile}', product: '${name}' (id: ${glamoId}).`
    )
  }

  if (!imageEdge) return null

  const gatsbyImageData = imageEdge.node.childImageSharp?.gatsbyImageData

  const fullSlug = `/produkt/${glamoId}/${slug}`

  return (
    <CardWrapper
      forwardedAs="article"
      width="100%"
      height="100%"
      flexDirection="column"
    >
      {gatsbyImageData && (
        <Link to={fullSlug} tabIndex={-1}>
          <ImgWrapper width="100%" boxShadow="subtle">
            <GatsbyImage
              image={gatsbyImageData}
              alt={`Zdjęcie przedstawiające: ${mainImageAlt || name}`}
              style={{
                objectFit: 'cover',
                objectPosition: '50% 50%',
                height: '100%',
                width: '100%',
              }}
            />
          </ImgWrapper>
        </Link>
      )}
      <Link to={fullSlug}>
        <Box
          marginTop="xs"
          minHeight={{
            _: 'calc(16 * 2 * 1.4 * 1px)',
            mobileL: 'calc(18 * 2 * 1.4 * 1px)',
          }}
        >
          <Text
            as="header"
            fontSize={{ _: 'xs', mobileL: 's' }}
            flexGrow={1}
            lineHeight="m"
            textAlign="center"
          >
            {name}
          </Text>
          <Text
            fontSize={{ _: 's', mobileL: 'm' }}
            paddingTop="xxxs"
            textAlign="center"
          >
            <strong>{price ? formatPrice(price) : '(b.d.)'}</strong>
          </Text>
        </Box>
      </Link>
    </CardWrapper>
  )
}

export default ProductListCard

const imagesQuery = graphql`
  query ProductsListImagesQuery {
    allProductsImages: allFile(
      filter: { relativeDirectory: { eq: "products" } }
    ) {
      edges {
        node {
          ...productImageThumbnail
          name
        }
      }
    }
  }
`

const ImgWrapper = styled(Box)`
  transition: box-shadow 0.2s ease-in;

  .gatsby-image-wrapper picture img {
    transition: transform 0.2s ease-in !important;
  }
`

const CardWrapper = styled(Flex)<FlexProps>`
  user-select: none;

  &:hover ${ImgWrapper}, &:active ${ImgWrapper}, &:focus ${ImgWrapper} {
    box-shadow: 2px 2px 8px 1px ${p => p.theme.colors.gray500};

    .gatsby-image-wrapper picture img {
      transform: scale(1.05);
    }
  }
`
