import { cloneDeepWith, identity, isEqual, sortBy } from 'lodash/fp'

type QueryObjectIsEqual = (
  objA: Record<string, unknown>,
  objB: Record<string, unknown>
) => boolean

export const queryObjectIsEqual: QueryObjectIsEqual = (objA, objB) => {
  const objASorted = cloneWithArraySorting(objA)
  const objBSorted = cloneWithArraySorting(objB)

  return isEqual(objASorted, objBSorted)
}

const arraySortingCustomizer = (
  element: Record<string, unknown> | ArrayLike<unknown>
) => {
  return Object.fromEntries(
    Object.entries(element).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, sortBy(identity, value)]
      }
      return [key, value]
    })
  )
}

const cloneWithArraySorting = <T extends Record<string, unknown>>(
  obj: T
): T => {
  return cloneDeepWith(arraySortingCustomizer, obj)
}
