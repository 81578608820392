import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { useMemo } from 'react'

import { SortProvider } from 'components/Context/SortProvider'
import Layout from 'components/Layout/Layout'
import ProductsList from 'components/ProductsList/ProductsList'
import SEO from 'components/SEO/SEO'
import { useMatchSlugToCategory } from 'hooks/useMatchSlugToCategory'
import { Query } from 'types/gatsby'

type DecorationsQuery = Pick<Query, 'allGlamoProduct' | 'site'>

interface DecorationsPageProps extends PageProps {
  params: {
    categorySlug: string
  }
}

const Decorations: React.FC<DecorationsPageProps> = ({ params }) => {
  const data = useStaticQuery<DecorationsQuery>(graphql`
    query DecorationsQuery {
      allGlamoProduct {
        edges {
          node {
            id
            glamoId
            name
            description
            price
            quantity
            slug
            mainImageFile
            mainImageAlt
            category
          }
        }
      }
    }
  `)

  const { categorySlug } = params
  const { activeCategory } = useMatchSlugToCategory({ categorySlug })

  const allProducts = useMemo(() => {
    return data.allGlamoProduct.edges.map(productEdge => productEdge.node)
  }, [data])

  return (
    <Layout>
      <SEO title={`Oferta - ${activeCategory.categoryDisplayName}`} />
      <SortProvider>
        <ProductsList products={allProducts} activeCategory={activeCategory} />
      </SortProvider>
    </Layout>
  )
}

export default Decorations
