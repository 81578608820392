import { Category } from 'data/categories'
import { GlamoProduct } from 'types/gatsby'
import { Box, Flex, Text } from 'ui/common'
import ProductsListFiltering from './ProductsListFiltering'
import ProductsListGrid from './ProductsListGrid'
import ProductsListSorting from './ProductsListSorting'
import { useFilteredSortedProducts } from './useFilteredSortedProducts'

interface ProductsListProps {
  products: Array<GlamoProduct>
  activeCategory: Category
}

const ProductsList: React.FC<ProductsListProps> = ({
  products,
  activeCategory,
}) => {
  const { categoriesIds, categoryDisplayName, categoryDescriptionHtml } =
    activeCategory

  const { visibleProducts } = useFilteredSortedProducts({
    products,
    categoriesIds,
  })

  return (
    <Box marginTop="xl">
      <Flex
        flexDirection={{ _: 'column', monitorS: 'row' }}
        paddingX={{
          _: 'xs',
          mobile: 's',
          tablet: 'l',
          monitorS: 'xxl',
          monitor: 'xxxl',
        }}
      >
        <ProductsListFiltering />
        <Flex
          position="relative"
          flexDirection="column"
          flexGrow={1}
          paddingLeft={{ monitorS: 'l' }}
        >
          <ProductsListSorting
            categoryDisplayName={categoryDisplayName}
            visibleProductsQuantity={visibleProducts.length}
          />

          {/* Category description */}
          <div dangerouslySetInnerHTML={{ __html: categoryDescriptionHtml }} />

          {visibleProducts.length === 0 ? (
            <Text textAlign="center">
              Brak produktów spełniających podane kryteria.
            </Text>
          ) : (
            <ProductsListGrid products={visibleProducts} />
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default ProductsList
