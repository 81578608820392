import { navigate } from 'gatsby'
import { useState, useEffect } from 'react'

import { defaultCategory, Category } from 'data/categories'
import { getCategoryFromSlug } from 'utils/categories'

interface UseMatchCategoriesFilteringProps {
  categorySlug?: string
}

export const useMatchSlugToCategory = ({
  categorySlug,
}: UseMatchCategoriesFilteringProps): {
  activeCategory: Category
} => {
  const [activeCategory, setFilteringCategory] = useState<Category>(
    getCategoryFromSlug(categorySlug)
  )

  useEffect(() => {
    // case: "/dekoracje/"
    if (!categorySlug && defaultCategory.slug) {
      navigate(`/dekoracje/${defaultCategory.slug}`, {
        replace: true,
      })
      return
    }

    // case: "/dekoracje/category-slug"
    if (categorySlug) {
      const matchedCategory = getCategoryFromSlug(categorySlug)
      setFilteringCategory(matchedCategory)
    }
  }, [categorySlug])

  return { activeCategory }
}
