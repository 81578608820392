import styled from 'styled-components'

import { GlamoProduct } from 'types/gatsby'
import { Grid, GridProps } from 'ui/common'
import ProductListCard from './ProductsListCard'

interface ProductsListGridProps {
  products: Array<GlamoProduct>
}

const ProductsListGrid: React.FC<ProductsListGridProps> = ({ products }) => {
  return (
    <ProductsGrid
      forwardedAs="section"
      marginX={{ _: 0, tablet: 0 }}
      marginTop="m"
      gridGap={{ _: '2rem 1rem', tablet: '2rem' }}
      gridTemplateColumns={{
        _: '1fr',
        mobile: '1fr 1fr',
        tablet: 'repeat(auto-fill, minmax(250px, 1fr))',
      }}
    >
      {products.map(product => (
        <ProductListCard key={product.glamoId} product={product} />
      ))}
    </ProductsGrid>
  )
}

export default ProductsListGrid

const ProductsGrid = styled(Grid)<GridProps>`
  justify-items: center;

  & > * {
    max-width: 360px;
  }
`
