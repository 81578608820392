import { useEffect, useState } from 'react'

import { useSortContext, SortType } from 'components/Context/SortContext'
import { CategoryId } from 'data/categories'
import { GlamoProduct } from 'types/gatsby'

export const useFilteredSortedProducts = ({
  products,
  categoriesIds,
}: {
  products: GlamoProduct[]
  categoriesIds: CategoryId[]
}) => {
  const { sortType } = useSortContext()

  const [visibleProducts, setVisibleProducts] = useState<GlamoProduct[]>(
    getFilteredSortedProducts({ products, categoriesIds, sortType })
  )

  useEffect(() => {
    setVisibleProducts(
      getFilteredSortedProducts({ products, categoriesIds, sortType })
    )
  }, [categoriesIds, products, sortType])

  return { visibleProducts }
}

const createFilterByCategoriesIds =
  (categoriesIds: CategoryId[]) =>
  (product: GlamoProduct): boolean => {
    const { category } = product

    return Boolean(category) && categoriesIds.includes(category as CategoryId) // TODO: graphql needs better typing
  }

const createSortProductBySortType =
  (sortType: SortType) => (productA: GlamoProduct, productB: GlamoProduct) => {
    const nameA = productA.name || ''
    const nameB = productB.name || ''
    const priceA = productA.price || 0
    const priceB = productB.price || 0

    switch (sortType) {
      case 'nameAsc':
        return nameA.localeCompare(nameB)

      case 'nameDesc':
        return nameB.localeCompare(nameA)

      case 'priceAsc':
        return priceA - priceB

      case 'priceDesc':
        return priceB - priceA

      case 'default':
      default: {
        const idA = parseIntFromUnknown(productA.glamoId)
        const idB = parseIntFromUnknown(productB.glamoId)

        return idA - idB
      }
    }
  }

const parseIntFromUnknown = (value: unknown, defaultInt = 0) => {
  if (typeof value === 'number') return Math.round(value)
  if (typeof value !== 'string') return defaultInt
  return Number.isNaN(parseInt(value, 10)) ? defaultInt : parseInt(value, 10)
}

interface GetFilteredSortedProductsParams {
  products: GlamoProduct[]
  categoriesIds: CategoryId[]
  sortType: SortType
}

const getFilteredSortedProducts = ({
  products,
  categoriesIds,
  sortType,
}: GetFilteredSortedProductsParams) => {
  // Filter products to show the ones that belong to currently visible categories
  const filterByCategoriesIds = createFilterByCategoriesIds(categoriesIds)
  // Sort products array by given sortType strategy
  const sortProductBySortType = createSortProductBySortType(sortType)

  return products.filter(filterByCategoriesIds).sort(sortProductBySortType)
}
